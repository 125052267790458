import { Link } from "react-router-dom";

export const Menu = () => (
  <>
    <Link to="/home">
      <p className="navbar-home-text">Home</p>
    </Link>
    {/* <Link to="/about">
      <p className="navbar-events-text">About Us</p>
    </Link>
    <Link to="/members">
      <p className="navbar-events-text">Our Team</p>
    </Link> */}
    {/* <Link to="/products"><p className='navbar-events-text'>Our Products</p></Link> */}
    {/* <Link to="/careers"><p className='navbar-events-text'>Careers</p></Link> */}
  </>
);
